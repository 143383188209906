import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useGetAllCategoryListQuery } from "../../../../utils/api/category/category.api";
import { usePagination } from "../../../../utils/hooks";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useSearchParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { QueryUrl, sortByAlphabetical } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";

let options = [
  {
    label: "Nombre d'avis",
    value: "avreageRate",
  },
  {
    label: "Date de création",
    value: "createdAt",
  },
  {
    label: "Nom",
    value: "name",
  },
  {
    label: "Note moyenne",
    value: "rateCount",
  },
  {
    label: "Prix",
    value: "price",
  },
];

const ordreDesCategories = ['Téléphone', 'Tablette', 'Montre connectée', 'Accessoire'];

function FilterMobileItem({
  searchParams,
  setSearchParams,
  query,
  setQuery,
  setPage,
  show,
  setShow,
}) {
  const [selectedCat, setSelectedCat] = React.useState<any>(
    searchParams?.get("sous_categorie")
      ? JSON.parse(searchParams?.get("sous_categorie") || "[]")
      : []
  );
  const [parent, setParent] = React.useState<any>(
    searchParams?.get("categorie") || ""
  );
  const [value, setValue] = React.useState<any>({
    min: searchParams?.get("prix_min") || "",
    max: searchParams?.get("prix_max") || "",
  });
  const {
    data = { results: [] },
    isLoading,
    refetch,
  } = useGetAllCategoryListQuery();

  // useEffect(() => {
  //   if (parent) {

  //   }
  // }, [parent])

  React.useEffect(() => {
    let params = QueryUrl("", query);
    setSearchParams(params);
  }, [query]);

  React.useEffect(() => {
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      sous_categorie: selectedCat?.length ? JSON.stringify(selectedCat) : "",
    };
    // console.log("query selectedCat", queryCopy);

    setQuery(queryCopy);
  }, [selectedCat]);

  const handleFilter = () => {
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      ...{ prix_min: value?.min, prix_max: value?.max },
    };

    setPage(1);
    setQuery(queryCopy);
  };

  useEffect(() => {
    handleFilter();
  }, [value]);

  const handleSelectedCat = (e: ChangeEvent<HTMLInputElement>) => {
    let selectedCopy = [...selectedCat];

    if (e?.target?.checked) {
      selectedCopy?.push(e?.target?.value);
    } else {
      selectedCopy = selectedCopy?.filter((el) => el !== e?.target?.value);
    }
    setSelectedCat(selectedCopy);

    setPage(1);
  };

  const handleClick = (item) => {
    setParent(item?.slug);
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      categorie: item?.slug,
      sous_categorie: [],
    };
    // console.log("query selectedCat", queryCopy);
    setSelectedCat([]);
    setQuery(queryCopy);
    setPage(1);
  };

  const handleClose = () => {
    setShow(false)
  }

  const trierCategories = (categories) => {
    let arrayCategorie = [...categories]
    return arrayCategorie?.sort((a, b) => {
      return ordreDesCategories.indexOf(a.nom) - ordreDesCategories.indexOf(b.nom);
    });
  };

  return (
    <Offcanvas show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton className="b-b-1">
        <Offcanvas.Title>Filtrer</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="mb-3">
          {/* <div className="content-btn-title-home-page py-3">
                    <div className="tab-dash-admin-filter-container">
                      <form className="tab-dash-admin-filter-form">
                        <div className="">
                          <label
                            htmlFor="filtreProduit"
                            className="tab-dash-admin-filter-label"
                            style={{ fontWeight: 700, color: "#000" }}
                          >
                            Trier par
                          </label>
                        </div>
                        <div className="">
                          <select
                            className="form-control filter-select-product"
                            value={searchParams?.get("orderBy") || ""}
                            onChange={(e) => handleSelect(e)}
                          >
                            <option value="">Pertinence</option>
                            {options?.map((opt, i) => (
                              <option value={opt?.value} key={i}>
                                {" "}
                                {opt?.label}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                      </form>
                    </div>
                  </div> */}
          <div className="row mt-3">
            {/* {!!isLoading && <ProductItemsSkeleton />} */}

            {/* {!isLoading &&
                      (data?.results?.length ? (
                        data?.results?.map((produit) => (
                          <div
                            className="col-md-6 col-lg-4 col-xl-3 mb-3 col-product-item d-flex"
                            key={produit._id}
                          >
                            <ProductItem item={produit} key={produit._id} />
                          </div>
                        ))
                      ) : (
                        <AlertInfo message="Aucune produit disponible pour le moment!" />
                      ))} */}
            {/* {!! ProductData && ProductData.map((product, key) => {
                        return(
                          <div
                            className="col-md-4 mb-3 col-product-item d-flex"  
                            key={key}
                          >
                            <ProductItem item={product} key={key} refetch={refetch} />
                          </div>
                        )
                      })
                      } */}
          </div>
          <div className="d-flex justify-content-center">
            {/* {data?.nbPage && data?.nbPage > 1 ? (
                      <Pagination
                        page={page}
                        total={data?.nbPage}
                        onPageChange={(page) => setPage(page)}
                      />
                    ) : null} */}
          </div>
        </div>
        <div className="content-others-value">
          <p className="content-title-section-left">Catégorie</p>
          {/* <div
            className="category-filter-list-check"
          >
            {data?.results?.map((item, i) => (
            <label className="material-checkbox mb-3" key={i}>
              <input defaultChecked={catgoryId==item?.id} type="checkbox"  name="categorie" value={item?.id} onChange={(e)=>handleSelectCategory(e)} />
              <span className="checkmark"></span>
                {item?.nom}
            </label>
            ))}
          </div> */}

          <div className="categories_and_subcategories">
            {!!isLoading && <p>Loading...</p>}

            <Accordion
              className="category-filter-acordion"
              id="accordionCategories"
              alwaysOpen
              defaultActiveKey={["0"]}
            >
              {!isLoading &&
                (trierCategories(data?.results)?.length ? (
                  trierCategories(data?.results)?.map((category, i) => (
                    <Accordion.Item
                      eventKey={`${i}`}
                      key={category?.id}
                      className="category-filter-acordion-item mb-1"
                    >
                      <Accordion.Header
                        className="mb-0"
                        onClick={() => handleClick(category)}
                      >
                        <span className="category_parent_name">
                          {category?.nom}
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        {category?.sous_categorie?.length ? (
                          sortByAlphabetical(category?.sous_categorie)?.map(
                            (sub) => (
                              <div
                                className="content-col-form-register-tabs cpntrol mb-1"
                                key={sub?.id}
                              >
                                <label className="label-checkbox-register-tabs category_child_name">
                                  <input
                                    type="checkbox"
                                    className="input-checkbox-register-tabs me-2"
                                    checked={
                                      selectedCat &&
                                      selectedCat?.length &&
                                      selectedCat?.includes(sub?.slug)
                                    }
                                    value={sub?.slug}
                                    onChange={(e) => handleSelectedCat(e)}
                                  />
                                  {sub?.nom}
                                </label>
                              </div>
                            )
                          )
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))
                ) : (
                  <AlertInfo message="Aucune catégorie disponible pour le moment" />
                ))}
            </Accordion>
          </div>
        </div>
        <p className="content-title-section-left">
          Prix <span style={{ fontWeight: 300, fontSize: 14 }}>(F CFA)</span>
        </p>
        <div className="pb-4 py-4">
          {/* <div className="filter-prix">
            <InputRange
              maxValue={100000}
              minValue={0}
              value={value}
              onChange={(value) => setValue(value)}
            />
          </div> 
          <div className="d-flex gap-2 mt-5 container-price align-items-center">
            <div className="price-container">{value?.min || 0}</div>-
            <div className="price-container">{value?.max || 0}</div>
          </div> */}
          <label className="material-checkbox mb-3" key="10">
            <input
              type="radio"
              value={10}
              name="prix"
              defaultChecked={true}
              onChange={() => setValue({ min: "", max: "" })}
            />
            <span>Tout</span>
          </label>
          <label className="material-checkbox mb-3" key="10">
            <input
              type="radio"
              value={10}
              name="prix"
              checked={parseInt(value?.max) === 10000}
              onChange={() => setValue({ min: 0, max: 10000 })}
            />
            <span>Jusqu'a 10 000</span>
          </label>
          <label className="material-checkbox mb-3" key="25">
            <input
              type="radio"
              value={25}
              name="prix"
              checked={parseInt(value?.max) === 25000}
              onChange={() => setValue({ min: 10000, max: 25000 })}
            />
            <span>De 10 000 à 25 000</span>
          </label>
          <label className="material-checkbox mb-3" key="50">
            <input
              type="radio"
              value={50}
              name="prix"
              checked={parseInt(value?.max) === 50000}
              onChange={() => setValue({ min: 25000, max: 50000 })}
            />
            <span>De 25 000 à 50 000</span>
          </label>
          <label className="material-checkbox mb-3" key="60">
            <input
              className="radio-input"
              type="radio"
              value={60}
              name="prix"
              checked={parseInt(value?.max) === 90000000000}
              onChange={() => setValue({ min: 50000, max: 90000000000 })}
            />
            <span>De 50 000 et plus</span>
          </label>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default FilterMobileItem;
