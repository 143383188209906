import React from "react";
import { NavLink } from "react-router-dom";
import Banner from "../../assets/images/appImages/img-banner.png";
import Deco from "../../assets/images/appImages/deco.png";
import Pimage from "../../assets/images/appImages/illustration.png";
import AliceCarousel from "react-alice-carousel";
import { useAppSelector } from "../../redux/hook";
import { getVendeur } from "./HomePage";
import Empty from "../../assets/images/appImages/category.png";
import { formatCurrency, getImage, truncateCaractere } from "../../utils/Utils";
import { useGetProductListQuery, useGetProductsEnAvantQuery } from "../../utils/api/product/product.api";
import { usePagination } from "../../utils/hooks";
import Skeleton from "react-loading-skeleton";

export const responsiveI = {
  0: { items: 1 },
  576: { items: 1 },
  768: { items: 1 },
  992: { items: 1 },
  1024: { items: 1 },
};



function BannerHomepage() {

  let { limit, page } = usePagination(12);
  const { data = { results: [] }, isLoading } = useGetProductListQuery({
    page: page,
    limit: limit,
  });
  const { data: produitsEnAvant } = useGetProductsEnAvantQuery({
    page: page,
    limit: limit,
  });


  return (
    <section className="section-banner-homepage">
      <div className="row align-items-center">
        <div className="col-md-7 mb-3 col-left-banner-accueil">
          <div className="content-col-left-banner-accueil">

            <div className="new_products__carousel position-relative">
              {!!isLoading && <BannerNewProductItemSkeleton />}
              {!isLoading &&
                <AliceCarousel
                  mouseTracking
                  responsive={responsiveI}
                  autoPlayStrategy="default"
                  controlsStrategy="alternate"
                  autoPlay={true}
                  infinite={true}
                  keyboardNavigation={true}
                  autoPlayInterval={6000}
                >
                  {produitsEnAvant?.count && produitsEnAvant?.count > 0
                    ? !!produitsEnAvant &&
                    produitsEnAvant?.results
                      ?.slice(
                        Math.max(produitsEnAvant?.results?.length - 10, 0)
                      )
                      ?.map((produit, key) => (
                        <div
                          className="col-product-item"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <BannerNewProductItem item={produit} key={key} />
                        </div>
                      ))
                    : !!data &&
                    data?.results
                      ?.slice(Math.max(data?.results?.length - 10, 0))
                      ?.map((produit, key) => (
                        <div className="col-product-item product-item-carousel">
                          <BannerNewProductItem item={produit} key={key} />
                        </div>
                      ))}
                </AliceCarousel>}
              <div className="show_new">
                <span>New</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 mb-3 col-right-banner-accueil">
          <div className="content-col-right-banner-accueil d-none d-lg-block">
            <div className="banner_left__last_products">
              {!!isLoading && <BannerNewProductItemSkeleton />}
              {!isLoading &&
                produitsEnAvant?.count && produitsEnAvant?.count > 0
                ? !!produitsEnAvant &&
                produitsEnAvant?.results
                  ?.slice(
                    Math.max(produitsEnAvant?.results?.length - 2, 0)
                  )
                  ?.map((produit, key) => (
                    <div
                      className="col-product-item"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <BannerNewProductItem item={produit} key={key} />
                    </div>
                  ))
                : !!data &&
                data?.results
                  ?.slice(Math.max(data?.results?.length - 2, 0))
                  ?.map((produit, key) => (
                    <div className="col-product-item product-item-carousel">
                      <BannerNewProductItem item={produit} key={key} />
                    </div>
                  ))}
            </div>

          </div>
        </div>
      </div>

    </section>
  );
}

export default BannerHomepage;


export const BannerNewProductItem = ({ item }: { item: any }) => {
  const { user, token } = useAppSelector((s) => s?.user);
  const Vendeur = getVendeur();

  const imgProd =
    item?.type == "taille_unique"
      ? item?.images[0]?.image
      : item?.variations && item?.variations[0]?.images
        ? item?.variations[0]?.images[0]?.image
        : null;

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return (
    <div className="new_product__item">
      <div className="row product-row">
        <div className="col-7">
          <div className="image-wrapper">
            <img
              src={imgProd ? getImage(imgProd) : Empty}
              alt={item?.nom}
              className="p-image"
            />
          </div>
        </div>
        <div className="col-5">
          <div className="product-infos">
            <div className="custom_container">
              <h3 className="name-product p-name">
                {window.innerWidth < 720
                  ? truncateCaractere(item?.nom, 39)
                  : truncateCaractere(item?.nom, 72)}
              </h3>
              <p className="py-2 custom_sm__title p-category">
                {item?.categorie?.nom}
              </p>
              <p className="m-0 pb-3 text-describe-product-item-detail p-description">
                {truncateCaractere(item?.description, 14)}...
                <NavLink
                  to={`/produit/${item?.slug}`}
                  className="no-link p-link"
                >
                  Voir plus
                </NavLink>
              </p>
              <div className="container-btn-banner-accueil">
                <NavLink
                  className="btn btn-title-home-page"
                  to={`/produit/${item?.slug}`}
                >
                  <span>
                    {formatCurrency(item?.prix, Vendeur?.devise)}
                  </span>
                </NavLink>
              </div>

            </div>

          </div>
        </div>

      </div>
    </div>
  );
};

const BannerNewProductItemSkeleton = () => {
  return (
    <>
      <div className="new_product__item">
        <div className="row product-row">
          <div className="col-7">
            <div className="image-wrapper">
              <Skeleton width="100%" height={350} />
            </div>
          </div>
          <div className="col-5">
            <div className="product-infos">
              <div className="custom_container">
                <Skeleton className="name-product p-name" width={100} />
                <Skeleton className="my-2 custom_sm__title p-category" width={100} />
                <div>
                  <Skeleton
                    className="m-0 mb-3 text-describe-product-item-detail p-description"
                    width={100}
                  />
                </div>
                <div className="container-btn-banner-accueil">
                  <Skeleton className="btn btn-title-home-page" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}