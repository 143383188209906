import React from "react";
import Logo from "../../../assets/images/appImages/logo-footer.svg";
import { NavLink, Link } from "react-router-dom";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { useGetAllCategoryListQuery } from "../../../utils/api/category/category.api";
import { FiPhoneCall } from "react-icons/fi";
import { useGetProductListQuery } from "../../../utils/api/product/product.api";
import { usePagination, useSearch } from "../../../utils/hooks";
import VersionChecker from "../../../versionChecker/VersionChecker";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { TfiWorld } from "react-icons/tfi";

const FrontFooter = () => {
  const { limit, page, onChange } = usePagination(12);
  const { data, refetch } = useGetAllCategoryListQuery();
  let date = new Date();

  return (
    <footer className="footer-component">
      <div className="container pt-4 pb-3">
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4 footer-grid ">
            <div className="content-logo-app-footer pb-5">
              <img
                loading="lazy"
                src={Logo}
                alt="logo"
                className="logo-app-footer"
              />
            </div>
            <p className="link-footer-title">Contactez-nous</p>
            <div className="ontact-us-container-footer">
              <div className="footer-contact-us-item mb-3">
                <span style={{ color: "#fff", paddingTop: 5 }}>
                  <AiOutlineWhatsApp style={{ fontSize: 18 }} />
                </span>
                <p className="link-footer mb-0 pb-0">
                  WhatsApp <br />
                  <Link to="https://wa.me/221776230404" className="no-link">
                    +221 77 623 04 04
                  </Link>
                </p>
              </div>
              <div className="footer-contact-us-item">
                <span style={{ color: "#fff", paddingTop: 5 }}>
                  <FiPhoneCall style={{ fontSize: 18 }} />
                </span>
                <p className="link-footer pb-0 mb-0">
                  Téléphone <br />
                  <Link to="tel:+221 77 488 34 58" className="no-link">
                    +221 77 488 34 58
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="mb-4 col-lg-4 col-md-6 footer-grid">
            <p className="link-footer-title title-footer-deco pb-4">
              Nos catégories
            </p>
            {data?.results?.map((item, i) => (
              <Link
                key={item?.id}
                state={{ catgoryId: item?.id }}
                className="link-footer link-footer-deco trans-0-2 d-block"
                to={`/produits?categorie=${item?.slug}`}
              >
                {item?.nom}
              </Link>
            ))}
          </div>
          <div className="mb-4 col-lg-4 col-md-6 footer-grid">
            <p className="link-footer-title title-footer-deco pb-4">
              Assistance
            </p>
            <NavLink
              className="link-footer link-footer-deco trans-0-2 d-block"
              to="/a-propos-de-nous"
            >
              Qui sommes nous ?
            </NavLink>
            <NavLink
              className="link-footer link-footer-deco trans-0-2 d-block"
              to="/politique-de-confidentialite"
            >
              Politique de confidentialité
            </NavLink>
            <NavLink
              className="link-footer link-footer-deco trans-0-2 d-block"
              to="/conditions-generales-de-ventes"
            >
              CGVs
            </NavLink>
            {/* <p className="m-0 pt-4 social-links-container d-flex align-items-center gap-3">
              <Link
                title="Facebook"
                className="social__wrapper"
                to="https://www.facebook.com/LOVEAFRICA.WEARAFRICA"
                target="_blank"
              >
                <FaFacebookF />
              </Link>
              <Link
                title="LinkedIn"
                className="social__wrapper"
                to="#"
                target="_blank"
              >
                <FaLinkedinIn />
              </Link>
              <Link
                title="Instagram"
                className="social__wrapper"
                to="https://www.instagram.com/abc_loveafricawear"
                target="_blank"
              >
                <FaInstagram />
              </Link>
              <Link
                title="X"
                className="social__wrapper"
                to="https://x.com/loveafricawear"
                target="_blank"
              >
                <BsTwitterX />
              </Link>
            </p> */}
          </div>
        </div>
        <div className="pt-4 pb-1 copyright-container">
          <div className="separator"></div>
          <div className="pt-3 text-white text-center copyright-text">
            © {date.getFullYear()}, PHONE HOUSE{" "}
            <span>Commerce électronique propulsé par</span>&nbsp;
            <Link
              to={`https://easymarket.sn`}
              target="_blank"
              className="no-link"
            >
              Easymarket.sn
            </Link>
          </div>
        </div>
        <div className="version-view-container text-end text-white fw-bold">
          {"v" + VersionChecker()}
        </div>
      </div>
    </footer>
  );
};

export default FrontFooter;
