import React, { useState } from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import "react-input-range/lib/css/index.css";
import FilterItems from "./FilterItems";
import StarRatingComponent from "react-star-rating-component";
import { useGetProductListQuery } from "../../../utils/api/product/product.api";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import { Product } from "../../../utils/api/product/product.type";
import { useGetPromotionListQuery } from "../../../utils/api/promotion/promotion.api";
import { Promotion } from "../../../utils/api/promotion/promotion.type";

let options = [
  {
    label: "Nombre d'avis",
    value: "avreageRate",
  },
  {
    label: "Date de création",
    value: "createdAt",
  },
  {
    label: "Nom",
    value: "name",
  },
  {
    label: "Note moyenne",
    value: "rateCount",
  },
  {
    label: "Prix",
    value: "price",
  },
];
const ParcoursAchat = () => {
  const { data: promotions = [] } = useGetPromotionListQuery({});
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchCategory, setSearchCategory] = useState<any>();
  const [searchSousCategory, setSearchSousCategory] = useState<any>();
  const [searchPrix, setSearchPrix] = useState<any>([]);
  const [show, setShow] = useState(false);

  const [query, setQuery] = React.useState({
    category: searchParams?.get("category") || "",
    min: searchParams?.get("min") || "",
    max: searchParams?.get("max") || "",
    orderBy: searchParams?.get("orderBy") || "",
  });
  const [value, setValue] = React.useState<any>({
    min: searchParams?.get("min") || 1000,
    max: searchParams?.get("max") || 10000,
  });
  const [page, setPage] = React.useState(1);
  const { data = { results: [], nbPage: 0 }, isLoading } =
    useGetProductListQuery({
      productType: "product",
      page,
      minPrice: searchParams?.get("min") || "",
      maxPrice: searchParams?.get("max") || "",
      orderBy: searchParams?.get("orderBy") || "",
      order: "ASC",
      category: searchParams?.get("category")
        ? JSON.parse(searchParams?.get("category") || "[]")
        : "",
    });
  const [lastPromo, setLastPromo] = React.useState<Promotion | undefined>();

  React.useEffect(() => {
    if (promotions?.length) {
      setLastPromo(promotions[promotions?.length - 1]);
    }
  }, [promotions]);

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      orderBy: e?.target?.value,
    };
    setQuery(queryCopy);
  };

  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content">
        <div className="container-easy-market">
          <div className="row row-achat-page mb-5">
            <FilterItems
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              query={query}
              setQuery={setQuery}
              setPage={setPage}
            />
            {/* <div className="container-filter-parcours-achat-mobile no-view-desktop">
                <button className="btn btn-filter-mobile" type="button" onClick={() => handleShow()}>
                  <FaFilter />
                  <span className="ps-2">Filtrer</span>
                </button>
            </div> */}
            <div className="col-lg-9 col-md-7 col-right-achat-page">
              <div className="content-col-right-achat-page">
                {!!promotions?.length && (
                  <div>
                    {/*<div
                      className="content-col-left-banner-home-page"
                      style={{ height: 300 }}
                    >
                      <div className="bg-img-animate"></div>

                       <div className="carousel-banner-home-page">
                        <div className="content-text-promo-reduction-bloc">
                          <div
                            className="content-text-col-left-banner"
                            style={{ width: "40%" }}
                          >
                            <h3 className="title-content-text-col-left-banner">
                              {lastPromo?.title}
                              !!!
                            </h3>
                            <div className="content-btn-col-left-banner pt-5">
                              <NavLink
                                to="/nos-promos"
                                className="btn btn-col-left-banner-home-page animate__animated animate__zoomIn animate__infinite"
                              >
                                J’en profite
                              </NavLink>
                            </div>
                          </div>
                          <div
                            className="content-reduction-promo-prix"
                            style={{ width: "inherit", height: "fit-content" }}
                          >
                            <div className="animate__animated animate__infinite animate__zoomIn">
                              <h4 className="text-title-reduction-promo-right mb-1">
                                JUSQU’À
                              </h4>
                              <h2 className="title-reduction-promo-right mb-0">
                                -{lastPromo?.taux}%
                              </h2>
                            </div>
                          </div>
                          <div className="content-img-promo-reduction d-flex justify-content-end">
                            <img loading="lazy"
                              src={getImage(lastPromo?.image)}
                              alt="Produit en promo"
                              style={{ height: "fit-content" }}
                              className="img-product-reduction-promo animate__animated animate__zoomInRight w-50"
                            />
                          </div>
                        </div>
                      </div> 
                    </div>
                    */}
                  </div>
                )}
                <div
                  className="container-home-page mb-3 no-view-mobile"
                  style={{ minHeight: "51rem" }}
                >
                  <div className="content-btn-title-home-page flex-r py-3">
                    <div className="tab-dash-admin-filter-container">
                      <form className="row g-3 tab-dash-admin-filter-form">
                        <div className="col-auto">
                          <label
                            htmlFor="filtreProduit"
                            className="tab-dash-admin-filter-label"
                            style={{ fontWeight: 700, color: "#000" }}
                          >
                            Trier par
                          </label>
                        </div>
                        <div className="col-auto">
                          <select
                            className="form-control filter-select-product"
                            value={searchParams?.get("orderBy") || ""}
                            onChange={(e) => handleSelect(e)}
                          >
                            <option value="">Pertinence</option>
                            {options?.map((opt, i) => (
                              <option value={opt?.value} key={i}>
                                {" "}
                                {opt?.label}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row mt-3">
                    {/* {!!isLoading && <ProductItemsSkeleton />} */}

                    {/* {!isLoading &&
                      (data?.results?.length ? (
                        data?.results?.map((produit) => (
                          <div
                            className="col-md-6 col-lg-4 col-xl-3 mb-3 col-product-item d-flex"
                            key={produit._id}
                          >
                            <ProductItem item={produit} key={produit._id} />
                          </div>
                        ))
                      ) : (
                        <AlertInfo message="Aucune produit disponible pour le moment!" />
                      ))} */}
                    {/* {!! ProductData && ProductData.map((product, key) => {
                        return(
                          <div
                            className="col-md-4 mb-3 col-product-item d-flex"  
                            key={key}
                          >
                            <ProductItem item={product} key={key} refetch={refetch} />
                          </div>
                        )
                      })
                      } */}
                  </div>
                  <div className="d-flex justify-content-center">
                    {/* {data?.nbPage && data?.nbPage > 1 ? (
                      <Pagination
                        page={page}
                        total={data?.nbPage}
                        onPageChange={(page) => setPage(page)}
                      />
                    ) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <FooterPage /> */}
        </div>
      </div>
      <FrontFooter />
      {/* <FilterMobileItem
          show={show}
          setShow={setShow}
          value={value}
          setValue={setValue}
          searchCategory={searchCategory}
          setSearchCategory={setSearchCategory}
          searchSousCategory={searchSousCategory}
          setSearchSousCategory={setSearchSousCategory}
          query={query}
          setQuery={setQuery}
          catgoryId={null}
       /> */}
    </div>
  );
};

export default ParcoursAchat;

export const ProductItemsSkeleton = () => {
  return (
    <>
      {Array(12)
        .fill(12)
        .map((item, i) => (
          <div
            className="col-md-6 col-lg-4 col-xl-3 mb-3 col-product-item d-flex"
            key={i}
          >
            <div
              className="product-item-container"
              style={{ textAlign: "center" }}
            >
              {/* <button className="btn icon-fav-product">
                <Skeleton width={20} height={20} circle={true} />
              </button> */}

              <div className="content-img-product-item">
                <Skeleton className="img-product-item" />
              </div>
              <div className="content-btn-buy-product-item py-3">
                <Skeleton
                  className="btn btn-buy-product-item bg-transparent"
                  width={`50%`}
                />
              </div>
              <div className="text-center">
                <Skeleton
                  className="price-product-item"
                  width={`60%`}
                  height={20}
                />
                <Skeleton
                  className="name-product fs-15"
                  height={15}
                  width={`70%`}
                />
                <div className="content-rate-product">
                  <StarRatingComponent
                    name="rate2"
                    starCount={5}
                    value={0}
                    starColor="#000000"
                    emptyStarColor="#D4D6D5"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export function AddedCartConform(item: Product) {
  const navigate = useNavigate();
  const onConfirm = async () => {
    await Swal.fire({
      // title: `Êtes-vous sûr de vouloir ?`,
      icon: "success",
      html: `<div class="sweet-container"> ${item?.nom} <strong>a été ajouté au panier</strong> </div>`,
      showCancelButton: true,
      confirmButtonText: "CONFIRMER",
      cancelButtonText: "POURSUIVRE MES ACHATS",
      showLoaderOnConfirm: true,
      iconColor: Color.default,
      confirmButtonColor: Color.default,
      customClass: {
        confirmButton: "btn btn-sweet-confirm",
        cancelButton: "btn btn-sweet-cancel",
      },
      reverseButtons: true,
    }).then((result: any) => {
      if (result?.isConfirmed) {
        navigate("/valider-commande");
      }
    });
  };
  return onConfirm;
}
